import { Container } from "@mantine/core";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { AuthenticationForm } from "../components/auth/AuthForm";

const Login = function () {
  return (
    <Container size="xs" p="xl">
      <AuthenticationForm />
    </Container>
  );
};
interface StaticProps {
  locale: string;
}
export async function getStaticProps({ locale }: StaticProps) {
  return {
    revalidate: 60,
    props: {
      ...(await serverSideTranslations(locale, ["common", "auth"])),
    },
  };
}
export default Login;
